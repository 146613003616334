/* eslint-disable no-unreachable */
/** @jsxRuntime classic */ /** @jsx jsx */
import {jsx, useTheme} from '@emotion/react'
import styled from '@emotion/styled/macro'
import * as React from 'react'
import * as mq from 'styles/media-queries'
import Modal from 'react-bootstrap/Modal'
import AccountInput from './account-input'
import {Loading} from './loading'
import {
  auth,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  signInWithGoogle,
} from '../API/firebase'
import {useAuthState} from 'react-firebase-hooks/auth'
import {reauthenticateWithCredential} from 'firebase/auth'

const LoginTitle = styled.div(({theme}) => ({
  fontSize: '32px',
  color: theme.colors.darkBlue,
  textAlign: 'center',
  fontWeight: 'bold',
  fontStyle: 'italic',
}))

const LoginSubtitle = styled.div(({theme}) => ({
  fontSize: '18px',
  color: theme.colors.blue,
  textAlign: 'center',
}))

const LoginFieldContainer = styled.div(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}))

const LoginFieldsWrapper = styled.div(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '20px',
  rowGap: '20px',
}))

const LoginButton = styled.button(({theme, disabled}) => ({
  border: 'none',
  marginTop: '10px',
  height: '40px',
  width: '350px',
  opacity: disabled ? '0.5' : '1',
}))

const SwitcherWrapper = styled.div(({theme}) => ({
  fontSize: '14px',
  color: theme.colors.lightGray,
}))

const SwitcherAction = styled.span(({theme}) => ({
  cursor: 'pointer',
  fontWeight: 'bold',
  color: theme.colors.gray,
}))

const DisclaimerLink = styled.a(({theme}) => ({
  color: theme.colors.washedBlue,
  cursor: 'pointer',
  ':hover': {
    color: theme.colors.blue,
    textDecoration: 'underline',
  },
}))

const ErrorDisplayer = styled.div(({theme}) => ({
  color: theme.colors.red,
  fontSize: '14px',
  marginTop: '10px',
  textAlign: 'center',
}))

export function LoginModal({show, handleClose}) {
  const [tab, setTab] = React.useState('LOGIN')
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [name, setName] = React.useState('')
  const [over21, setOver21] = React.useState(false)
  const [user, loading, error] = useAuthState(auth)
  const [loadingLogin, setLoadingLogin] = React.useState(false)
  const [loginError, setLoginError] = React.useState(null)
  const isLogin = tab === 'LOGIN'
  const isRegister = tab === 'REGISTER'
  const isReset = tab === 'RESET'
  const [showTerms, setShowTerms] = React.useState(false)
  const theme = useTheme()

  React.useEffect(() => {
    if (user) {
      setLoadingLogin(false)
      handleClose()
    }
  }, [user, loading])

  const handleTermsClose = () => {
    setShowTerms(false)
  }

  const handleLogin = async () => {
    setLoginError(null)
    setLoadingLogin(true)
    const response = await logInWithEmailAndPassword(email, password)
    if (response.error) {
      setLoginError(response.message)
    }
    setLoadingLogin(false)
  }

  const handleGoogleLogin = async () => {
    setLoadingLogin(true)
    await signInWithGoogle()
    setLoadingLogin(false)
  }

  const handleRegister = async () => {
    setLoadingLogin(true)
    const response = await registerWithEmailAndPassword(name, email, password)
    if (response.error) {
      setLoginError(response.message)
    }
    setLoadingLogin(false)
  }

  const handleReset = async () => {
    setLoadingLogin(true)
    await sendPasswordReset(email)
    setLoadingLogin(false)
  }

  if (!isLogin) {
    if (isReset) {
      return (
        <Modal show={show} onHide={handleClose}>
          <Modal.Body css={{padding: '20px 20px 60px'}}>
            <CloseButton onClose={handleClose} />
            <LoginTitle>RESET PASSWORD</LoginTitle>
            <LoginFieldsWrapper>
              <LoginFieldContainer>
                <AccountInput
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  label="Email"
                  placeholder={'example@mail.com'}
                  width={350}
                />
              </LoginFieldContainer>
              <ActionButtons
                loading={loadingLogin}
                loginText="Send password reset email"
                onLogin={handleReset}
              />
              <SwitcherWrapper>
                Already have an account?{' '}
                <SwitcherAction
                  css={{cursor: 'pointer'}}
                  onClick={() => setTab('LOGIN')}
                >
                  Login
                </SwitcherAction>
              </SwitcherWrapper>
            </LoginFieldsWrapper>
          </Modal.Body>
        </Modal>
      )
    } else {
      return (
        <Modal show={show} onHide={handleClose}>
          <Modal.Body css={{padding: '20px 20px 60px'}}>
            <CloseButton onClose={handleClose} />
            <LoginTitle>WELCOME TO PITHIA</LoginTitle>
            <LoginSubtitle>Register for free pickas and more!</LoginSubtitle>
            <LoginFieldsWrapper>
              <LoginFieldContainer>
                <AccountInput
                  value={name}
                  onChange={e => setName(e.target.value)}
                  label="Full name"
                  placeholder={'Enter your full name'}
                  width={350}
                />
              </LoginFieldContainer>
              <LoginFieldContainer>
                <AccountInput
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  label="Email"
                  placeholder={'example@mail.com'}
                  width={350}
                />
              </LoginFieldContainer>
              <LoginFieldContainer>
                <AccountInput
                  value={password}
                  type="password"
                  onChange={e => setPassword(e.target.value)}
                  label="Password"
                  placeholder={'6+ strong character'}
                  width={350}
                />
              </LoginFieldContainer>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  columnGap: '10px',
                  width: '350px',
                }}
              >
                <input
                  type="checkbox"
                  value={over21}
                  onClick={e => setOver21(e.target.checked)}
                />
                <span css={{fontSize: '14px', color: theme.colors.lightGray}}>
                  I'm over the age of 21 and accept the{' '}
                  <DisclaimerLink onClick={() => setShowTerms(true)} href="#">
                    Terms of Service Agreement
                  </DisclaimerLink>
                </span>
              </div>
              <ActionButtons
                loading={loadingLogin}
                loginText="Register for free picks"
                googleText="Sign up with Google"
                onLogin={handleRegister}
                onGoogle={handleGoogleLogin}
                google
                disabled={!over21}
              />
              <SwitcherWrapper>
                Already have an account?{' '}
                <SwitcherAction onClick={() => setTab('LOGIN')}>
                  Sign in
                </SwitcherAction>
              </SwitcherWrapper>
            </LoginFieldsWrapper>
            {showTerms && (
              <div
                css={{
                  backgroundColor: 'white',
                  position: 'absolute',
                  borderRadius: '5px',
                  bottom: 0,
                  top: 0,
                  left: 0,
                  right: 0,
                  padding: '20px 35px',
                  overflow: 'scroll',
                }}
              >
                <div
                  css={{
                    display: 'flex',
                    width: '100%',
                    height: '20px',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <CloseButton onClose={handleTermsClose} />
                </div>
                <p
                  css={{
                    textAlign: 'center',
                    margin: '15px auto',
                    fontWeight: 'bold',
                  }}
                >
                  Terms and Conditions
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                  ut fringilla massa. Nullam faucibus hendrerit ligula, ac
                  scelerisque libero hendrerit vel. Vestibulum tincidunt mauris
                  vitae dapibus ornare. Aliquam erat volutpat. Praesent ornare
                  placerat nunc in dictum. Duis porttitor nibh non justo
                  venenatis, quis varius mauris vulputate. Sed justo nibh,
                  volutpat at fringilla a, faucibus sed mi. Nulla justo diam,
                  blandit vel ullamcorper et, placerat ut velit. Donec rhoncus,
                  orci quis consequat fringilla, ligula diam vehicula nisi, eu
                  convallis metus sapien eu ligula. Ut commodo auctor placerat.
                  Praesent congue, ante a tempus maximus, nulla augue sodales
                  massa, id sagittis nibh neque nec nunc. Etiam lectus nunc,
                  aliquet in fermentum sed, euismod in ante. Ut quis pretium
                  enim, sit amet imperdiet turpis. Sed faucibus, tortor in
                  elementum vehicula, dui lacus iaculis ante, ut auctor elit
                  purus in augue. Aliquam lacinia odio justo, et hendrerit eros
                  hendrerit vitae. Vestibulum sagittis mauris posuere, tempus
                  ipsum vel, feugiat nisi. Donec dapibus orci tristique massa
                  interdum commodo. Sed auctor dui arcu, in mattis sem facilisis
                  nec. Fusce diam lorem, ultrices eget nisl a, finibus aliquet
                  lacus. Vivamus eleifend eros vel dui varius aliquet. Morbi
                  dignissim felis sit amet magna bibendum efficitur. Nullam
                  tempor ornare augue, et euismod arcu porta cursus. Morbi
                  placerat at risus quis varius. Quisque ultrices libero sed
                  massa gravida, et ultrices libero luctus. Ut bibendum odio ac
                  gravida mattis. Nam tempus condimentum neque, quis posuere
                  sapien facilisis ac. Etiam sollicitudin, odio quis pharetra
                  consequat, dolor ipsum ultricies lectus, aliquam pretium
                  sapien ipsum eget lorem. Ut posuere nibh eu nisl rhoncus
                  semper. Quisque mollis non justo eu porta. Duis odio dui,
                  ornare in consectetur sit amet, porttitor id diam.
                </p>
              </div>
            )}
          </Modal.Body>
        </Modal>
      )
    }
  } else {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Body css={{padding: '20px 20px 60px'}}>
          <CloseButton onClose={handleClose} />
          <LoginTitle>WELCOME BACK!</LoginTitle>
          <LoginSubtitle>Welcome back! Please enter your details</LoginSubtitle>
          {loginError && <ErrorDisplayer>{loginError}</ErrorDisplayer>}
          <LoginFieldsWrapper>
            <LoginFieldContainer>
              <AccountInput
                value={email}
                onChange={e => setEmail(e.target.value)}
                label="Email"
                placeholder={'example@mail.com'}
                width={350}
              />
            </LoginFieldContainer>
            <LoginFieldContainer>
              <AccountInput
                value={password}
                type="password"
                onChange={e => setPassword(e.target.value)}
                label="Password"
                placeholder={'6+ strong character'}
                width={350}
              />
            </LoginFieldContainer>
            <div
              css={{
                color: theme.colors.darkGray,
                fontSize: '13px',
                fontWeight: 'bold',
              }}
            >
              <span css={{cursor: 'pointer'}} onClick={() => setTab('RESET')}>
                Forgot password?
              </span>
            </div>
            <ActionButtons
              loading={loadingLogin}
              loginText="Sign in"
              googleText="Sign in with Google"
              onLogin={handleLogin}
              onGoogle={handleGoogleLogin}
              google
            />
            <SwitcherWrapper>
              Dont have an account?{' '}
              <SwitcherAction onClick={() => setTab('REGISTER')}>
                Register for free
              </SwitcherAction>
            </SwitcherWrapper>
          </LoginFieldsWrapper>
        </Modal.Body>
      </Modal>
    )
  }
}

const ActionButtons = ({
  loading,
  loginText,
  googleText,
  onLogin,
  google,
  onGoogle,
  disabled,
}) => {
  const theme = useTheme()
  if (loading) {
    return (
      <div css={{display: 'flex', justifyContent: 'center'}}>
        <Loading small />
      </div>
    )
  } else {
    return (
      <div css={{display: 'flex', flexDirection: 'column'}}>
        <LoginButton
          disabled={disabled}
          css={{
            backgroundColor: theme.colors.brightBlue,
            color: theme.colors.white,
          }}
          onClick={onLogin}
        >
          {loginText}
        </LoginButton>
        {google && (
          <LoginButton
            disabled={disabled}
            css={{
              borderColor: theme.colors.lighterGray,
              border: `solid 2px ${theme.colors.lighterGray}`,
              backgroundColor: theme.colors.white,
              color: theme.colors.gray,
            }}
            onClick={() => signInWithGoogle()}
          >
            <img
              src="/images/social/google.png"
              css={{height: '20px', width: '20px', marginRight: '10px'}}
              alt="Google"
            ></img>
            {googleText}
          </LoginButton>
        )}
      </div>
    )
  }
}

const CloseButton = ({onClose}) => (
  <div
    css={{
      display: 'flex',
      justifyContent: 'flex-end',
      cursor: 'pointer',
    }}
    onClick={onClose}
  >
    <img
      src="/images/close.png"
      css={{height: '12px', width: '12px'}}
      alt="close"
    />
  </div>
)
