/** @jsxRuntime classic */ /** @jsx jsx */
import {jsx, useTheme} from '@emotion/react'

import {css, Global} from '@emotion/react'

function GlobalStyles() {
  const theme = useTheme()

  return (
    <Global
      styles={css`
        body {
          font-family: ${theme.fonts.primary};
          position: relative;
        }
        a,
        a:hover {
          color: inherit;
          text-decoration: none;
        }
      `}
    />
  )
}

export {GlobalStyles}
