/** @jsxRuntime classic */ /** @jsx jsx */
import {jsx, useTheme} from '@emotion/react'
import * as React from 'react'
import styled from '@emotion/styled/macro'
import * as mq from 'styles/media-queries'
import {useMediaQuery} from 'utils/hooks'

const InputGroup = styled.fieldset(({isMobile, theme, focused, fullWidth}) => ({
  padding: '0 15px;',
  border: `2px solid`,
  borderColor: focused ? theme.colors.brightBlue : theme.colors.semiLightGray,
  borderRadius: '4px',
  width: isMobile || fullWidth ? '100%' : '586px',
}))

const InputLabel = styled.legend(({isMobile, theme, focused}) => ({
  color: focused ? theme.colors.brightBlue : theme.colors.semiLightGray,
  fontSize: '16px',
  marginBottom: '-5px',
  padding: '0 10px',
  width: isMobile ? '100%' : 'auto',
}))

const InputWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  columnGap: '5px',
})

const InputInput = styled.input(({theme}) => ({
  border: 'none',
  color: theme.colors.gray,
  padding: '10px 0',
  width: '100%',
  ':focus': {outline: 'none'},
  '::placeholder': {
    color: theme.colors.lighterGray,
    opacity: 1,
  },
}))

const InputSelect = styled.select(({theme}) => ({
  border: 'none',
  color: theme.colors.gray,
  padding: '10px 0',
  width: '100%',
  ':focus': {outline: 'none'},
}))

const InputSelectOption = styled.option(({theme}) => ({
  ':disabled': {color: theme.colors.lighterGray},
}))

function AccountInput({
  label,
  id,
  focused,
  onFocus,
  onBlur,
  imgPlaceholder,
  placeholder,
  fullWidth,
  ...props
}) {
  const [focusedInput, setFocusedInput] = React.useState(false)
  const isMobile = useMediaQuery(mq.small)
  return (
    <InputGroup
      isMobile={isMobile}
      focused={focusedInput}
      fullWidth={fullWidth}
    >
      <InputLabel focused={focusedInput}>{label}</InputLabel>
      <InputWrapper>
        <InputInput
          type="text"
          class="form-control input-lg"
          placeholder={placeholder}
          onFocus={() => {
            setFocusedInput(true)
          }}
          onBlur={() => {
            setFocusedInput(false)
          }}
          {...props}
        />
        {imgPlaceholder && (
          <img
            alt={label}
            src={imgPlaceholder}
            css={{height: '20px', width: '20px'}}
          />
        )}
      </InputWrapper>
    </InputGroup>
  )
}

export default AccountInput
