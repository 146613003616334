/** @jsxRuntime classic */ /** @jsx jsx */
import {jsx, useTheme} from '@emotion/react'
import ClipLoader from 'react-spinners/ClipLoader'

import * as mq from 'styles/media-queries'

function Loading({small}) {
  const theme = useTheme()
  if (!small) {
    return (
      <div
        css={{
          height: '318px',
          textAlign: '-webkit-center',
          width: '100%',
          marginTop: '80px',
          [mq.small]: {
            marginBottom: '52px',
            marginRight: '0px',
          },
        }}
      >
        <img
          src="/images/nodata.svg"
          alt="No Data"
          css={{
            textAlign: 'center',
            height: '50px',
            overflow: 'hidden',
            transitionDuration: '0.8s',
            transitionProperty: 'transform',
            transform: 'rotate(360deg)',
            WebkitTransform: 'rotate(36 0deg)',
          }}
        />
        <h3
          css={{
            width: '282px',

            textAlign: 'center',
            color: theme.colors.blue,
            fontSize: '10px',
          }}
        >
          Loading...
        </h3>
        <ClipLoader
          color={theme.colors.blue}
          loading={true}
          css={{
            display: 'flex',
            margin: '0 auto',
            borderColor: theme.colors.blue,
          }}
          size={25}
        />
      </div>
    )
  } else {
    return (
      <ClipLoader
        color={theme.colors.blue}
        loading={true}
        css={{
          display: 'flex',
          margin: '0 auto',
          borderColor: theme.colors.blue,
        }}
        size={25}
      />
    )
  }
}

export {Loading}
